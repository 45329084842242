  
  a {
    color: red;
    text-decoration: none;
  }

  .about h2 {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    color: black;
  }
  #c1
  {
    right: 0;
  }
  .about .our-story {
    padding: 30px;
    background-color: #ebf0f4;
  }
  
  .about .our-story h4 {
    text-transform: uppercase;
    font-size: 18px;
    color: #0f0f10;
  }
  
  .about .our-story h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: red;
  }
  
  
  
  .about .watch-video i {
    font-size: 32px;
    transition: 0.3s;
    color: red;
  }
  
  .about .watch-video a {
    font-weight: 600;
    margin-left: 8px;
    transition: 0.3s;
  }
  
  .about .watch-video:hover a {
    color: rgb(186, 27, 27);
  }
  
  /* .about .about-img {
    background-size: cover;
    background-position: center;
  } */

  .our-story-img{
    height: 500px;
  }
  
 
  .about-boxes {
    background: url("https://img.freepik.com/free-psd/interior-modern-emergency-room-with-empty-nurses-station-generative-ai_587448-2137.jpg?w=826&t=st=1706606313~exp=1706606913~hmac=ee2ee64ee6fd18bc00a24a94d314ec77129fcadbbcc885da3b57c4fb72f2f7cc") center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 30px 0;
    position: relative;
  }
  
  .about-boxes::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 9;
  }
  
  .about-boxes .container,
  .about-boxes .container-fluid {
    position: relative;
    z-index: 10;
  }
  
  .about-boxes .card {
    border-radius: 3px;
    border: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .about-boxes .card-icon {
    text-align: center;
    margin-top: -32px;
  }
  
  .about-boxes .card-icon svg {
    font-size: 22px;
    color: #fff;
    width: 64px;
    height: 64px;
    padding-top: 5px;
    text-align: center;
    background-color: red;
    border-radius: 4px;
    text-align: center;
    border: 4px solid #fff;
    transition: 0.3s;
    display: inline-block;
  }
  
  .about-boxes .card-body {
    padding-top: 12px;
  }
  
  .about-boxes .card-title {
    font-weight: 700;
    text-align: center;
  }
  
  .about-boxes .card-title a {
    color: #15222b;
  }
  
  .about-boxes .card-title a:hover {
    color: red;
  }
  
  
  
  .about-boxes .card:hover .card-icon i {
    
    color: #ff4a17;
  }
  
 
  


  .about2 .container {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
    
  }

  
  .about2 .content {
    margin-top: 20px;
    font-size: 15px;
  }
  
  .about2 .content h3 {
    font-weight: 700;
    font-size: 24px;
    color: red;
  }
  
  

  
   .about2 .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(red 50%, rgba(243, 44, 8, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about2 .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about2 .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 74, 23, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about2 .play-btn:hover::after {
    border-left: 15px solid #ff4a17;
    transform: scale(20);
  }
  
  .about2 .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
   