/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-color: rgba(60, 60, 60, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#hero .container {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-top: 4px solid red;
}

@media (max-width: 1200px) {
  #hero .container {
    margin-left: 50px;
    margin-right: 50px;
  }
}

#hero h2 {
  color: #2f2f2f;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
}

#hero p {
  margin: 0 auto 30px auto;
  color: #555555;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  width: auto;
  height: auto;
  background: var(--light-red);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 1);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: var(--red);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: var(--light-red);
}

#hero .btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  background: red;
}

#hero .btn-get-started:hover {
  background: var(--dark-red);
}

@media (max-width: 992px) {
  #hero {
    height: 100vh;
  }

  #hero .container {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    display: none;
  }
}

@media (min-width: 1024px) {

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 160vh;
  }
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 30px;
  background: red;
  border-radius: 4px;
  color: #fff;
}

.why-us .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.why-us .content p {
  margin-bottom: 30px;
}

.why-us .content .more-btn {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.why-us .content .more-btn i {
  font-size: 14px;
}

.why-us .content .more-btn:hover {
  color: var(--red);
  background: #fff;
}

.why-us .icon-boxes .icon-box {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.why-us .icon-boxes .icon-box i {
  font-size: 40px;
  color: #1977cc;
  margin-bottom: 30px;
}

.why-us .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px 0;
}

.why-us .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: red;
  bottom: 0;
  left: calc(50% - 25px);
}


.section-title p {
  margin-bottom: 0;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul svg {
  font-size: 20px;
  padding-right: 4px;
  color: red;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-bottom: 30px;
}

.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}

.counts .count-box svg {
  display: block;
  font-size: 45px;
  color: red;
  float: left;
}

.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #555555;
  margin-left: 50px;
}

.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7b7b7b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: red;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid #fff;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: var(--light-red);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: #faf1f0;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}
.awards-slider .swiper-pagination,
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.awards-slider .swiper-pagination .swiper-pagination-bullet,
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid red;
}
.awards-slider .swiper-pagination .swiper-pagination-bullet-active,
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: red;
}


.card-award {
  border: 0;
  border-radius: 0;
  transition: all 300ms linear 0s;
}
.card-img{
  width: 100%;
  height: 300px;
}

@media (max-width: 991px) {
  .card-award {
    margin-bottom: 30px;
  }
}

.card-award__body {
  border: 1px solid #e8edf1;
  border-top: 0;
  padding: 28px 30px 6px;
  transition: all 300ms linear 0s;
}

@media (max-width: 1199px) {
  .card-award__body {
    padding: 20px 20px 6px;
  }
}

.card-award__body h3 {
  font-size: 20px;
  margin-bottom: 3px;
  font-weight: bold;
}

.card-award__body p {
  margin-bottom: 16px;
  color: #2a2a2a;
}

.card-award:hover {
  background: #fff;
}

.card-award .award-footer {
  padding-top: 12px;
  border-top: 1px solid #e8edf1;
}

.card-award .award-footer .dn_btn {
  font-size: 14px;
  color: #797979;
}

.card-award .award-footer .dn_btn i {
  margin-right: 9px;
  font-size: 13px;
}


/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 250px;
}

.services .img img {
  transition: 0.6s;
  width: 100%;
  height: 250px;
}

.services .details {
  padding: 50px 30px;
  margin: -100px 30px 0 30px;
  transition: all ease-in-out 0.3s;
  background: var(--color-white);
  position: relative;
  background: rgba(255,255,255, 0.9);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(0,0,0, 0.1);
}

.services .details .icon {
  margin: 0;
  width: 72px;
  height: 72px;
  background: red;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #fff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  border: 6px solid #fff;
}

.services .details h3 {
  color: #000;
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
}

.services .details p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover .details h3 {
  color: red;
}

.services .service-item:hover .details .icon {
  background: #fff;
  border: 2px solid red;
}

.services .service-item:hover .details .icon svg {
  color: red;
}

.services .service-item:hover .img img {
  transform: scale(1.2);
}
