.carousel-item active {
  width: 300px;
  /* Set the width of the container */
  height: 200px;
  /* Set the height of the container */
  overflow: hidden;
  /* Hide any overflow */
}

.carousel-item active backgroundImage {
  max-width: 100%;
  /* Set the maximum width of the image to 100% of its container */
  max-height: 100%;
  /* Set the maximum height of the image to 100% of its container */
  display: block;
  /* Ensure the image is displayed as a block element */
  margin: auto;
  /* Center the image horizontally */
}