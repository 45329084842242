.service-text .card-body p{
    font-size: 20px;
    margin: 30px 0px;
}
.service-text .card-body h3{
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 20px;
}
.card-title{
    text-align: center;
}
.narrow-container{
    max-width: 600px;
}


 