.appointment-form {
    
  margin-top: 140px;

}
.appointment-form h1{
  text-align: center;
}
.appointment-form .form-check-label{
  margin-left: 10px;
}
.appointment-form .submit-btn{
  margin-left: 7%;
  height: 50px;
  width: 80%;
}
@media (min-width: 500px) {
  .appointment-form .container{
      max-width: 480px;
  }
}

@media only screen and (max-width: 600px) {
  .time-slot {
    font-size: 14px;
  }
}
  /* .appointment-form {
    
    display: flex;    
    align-items: center;
    justify-content: center;

  }
  
  .appointment-form form {
    
    max-width: 300px;
    margin-top: 150px;
    padding: 10px 20px;
    background: white;
  }
  
  .appointment-form h1 {
    margin: 0 0 30px 0;
    text-align: center;
  }
  .appointment-form
  input[type="text"],
  .appointment-form
  input[type="date"],
  .appointment-form
  input[type="email"],
  .appointment-form
  input[type="number"],
  .appointment-form
  input[type="tel"]
  {
    background: rgba(255,255,255,0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px;
    width: 100%;
    background-color: #e8eeef;
    color: black;
    margin-bottom: 30px;
  }
  
  
.appointment-form select {
  background-color: #e8eeef;
  border: none;
  font-size: 16px;
  padding: 15px;
  width: 100%;
  margin-bottom: 30px;
}
    
.appointment-form .time-slots {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.appointment-form .time-slot:hover{
  background-color: green;
}
.appointment-form #submit-appointment {
    padding: 19px 39px 18px 39px;
    color: #FFF;
    background-color: #FF0000;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .appointment-form #submit-appointment:hover{
    background-color: green;
}
  
  
.appointment-form label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
}

@media screen and (min-width: 480px) {

  .appointment-form form {
    max-width: 480px;
  }

}
   */