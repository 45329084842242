.blog-text .card-body p{
    font-size: 20px;
    margin: 30px 0px;
}
.blog-text .card-body h3{
    font-weight: 600;
    font-size: 35px;
    margin-bottom: 20px;
}
.blog-text .bio{
    margin-right: 30px;
}
.blog-text .about-author-container{
    margin-top: 30px;
}
.blog-text .about-author-container h3{
   font-weight: 600;
}
.blog-text .about-author-container p{
    font-size: 19px;
 }

 .book-appointment-container{
    margin-top: 0px;
    
 }