.service-posts{
  margin-top: 130px;
}

.service-posts .card{
  width: 16rem;
}
.service-posts h2{
  font-size: 40px;
  margin-bottom: 25px;
  font-weight: 600;
}
.service-card{
  width: 32%;
  margin: 15px auto ; 
}
.service-post{
  margin: 15px auto ;  
}
.service-post h5{
  font-weight: 700;
  margin-bottom: 15px;
}
.service-post img{
  margin-bottom: 20px;
}
.btn{
  margin-bottom: 10px;
}
.service-post .right-btn{
  margin-left: 70px;
}

@media screen and (max-width: 768px) {
  .service-card{
      width: 90%;
      margin: 15px auto;
  }
}