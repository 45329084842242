body {
  background-color: #fbfbfb;
}
@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

.img-f
{
    width: 100%;
    height: 100%;
    /* max-width: 100%; */
}
/* Sidebar */
.adminNav .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .adminNav  .sidebar {
    width: 100%;
  }
}
.adminNav .sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background-color: red;
}

.adminNav .sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.adminNav .sidebar .list-group-item{
  border-bottom: 1px solid #f0eded;
}
.adminNav .sidebar .list-group-item svg{
  font-weight: bold;
  margin-right: 20px;
  font-size: 24px;
}

.admin{
  margin-top: 100px;
  margin-left: 250px;
}

.admin .form-group label{
  font-weight: bold;
  font-size: 18px;
}
.btn-danger{
  color: white;
  background: red;
}

.table-heading{
color: red;
font-weight: bolder;
font-size: 40px;
}

.table button{
  color: red;
  margin: auto;
  background: #fff;
  border: none;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  align-items: center;
  border-radius: 3px;
  font-size: 18px;
  padding: 5px;
}
.index-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.index-image {
  width: 100%; 
  max-height: 100%; 
  width: auto;
  height: auto;
}

